.cardContent{
    margin: 5px 10px;
    color: #5e6c84;

}

.background {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 2px 2px 2px 1px #7a7a7a;
    word-wrap: break-word;
}

.cardBar{
    display: flex;
    justify-content: space-between;
    width: 100%;
    color: #383838;
    font-size: 14px;
    font-weight: bold;
    opacity: 1;
    height: 20px;
    transition: opacity 0.3s ease-in-out, height 0.2s ease-in-out;

}

.hide{
    opacity: 0;
    height: 0;
}


.listCard{
    width: 100%;    
}

.icon:hover {
    cursor: pointer;
}
.icon:active {
    color: #7f7f7f;

}