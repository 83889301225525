.content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-right: 20px;

}

.title{
    margin: auto;
    text-align: center;
    min-width: 180px;
    max-width: 330px;
    padding: 5px 10px;
    box-shadow: inset 0px 1px 1px 1px #7a7a7a;
    border-radius: 10px 10px 0 0;
    color: #1c335b;
    background-color: #ebecf0;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #5a5959;

}

.icons{
    width: 100%;
    display: flex;
    justify-content: center;

}
.icon{
    display: flex;
}

.icon:hover{
    cursor: pointer;
    opacity: 0.7;
}


.backGround{
    background-color: #ebecf0;
    border-radius: 20px;
    box-shadow: inset 0px 0px 1px 1px #7a7a7a;

}