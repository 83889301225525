.buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
}

.icon:hover {
    color: #585858;
}
.icon:active {
    color: #7f7f7f;

}

.cardContent{
    
    margin: 5px 10px;
    color: #5e6c84;
}

.background {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 2px 2px 2px 1px #7a7a7a;
    word-wrap: break-word;
}