.container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    min-height: 56px;
    margin-top: 20px;
    margin-bottom: 10px;
    
}

.icon:hover {
    color: #585858;
}
.icon:active {
    color: #7f7f7f;

}

.buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
}